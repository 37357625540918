import React, { useState, Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import birthcit from '../../images/birthcit.jpg';

import './TopicPage.css';

const BirthRight = () => {

  const [showMap, setShowMap] = useState(false);
  // const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  return (
    <Fragment>
    <Modal
      show={showMap} onCancel={closeMapHandler}
      header='For forms and info'
      contentClass="topic-page__modal-content"
      footerClass='topic-item__modal-actions'
      footer={ <Button onClick={closeMapHandler}>Close</Button>  }    
      >

      <div className='text-container'>
        <p>Please visit:
          <a rel='noreferrer' href="https://www.uscis.gov/n-400">
          US Citizenship and Immigration website.</a>
        </p>
      </div>
    </Modal>


    <div className='topic-page'>
      <Card className='topic-page__card'>
        
        <div className="topic-page__image">
          <img src={birthcit} alt='naturalization test'/>
        </div>

        <div className="topic-page__info topic-large">
          <h1>Birthright Citizenship</h1>
          <div className="topic-page__content">
          <section className='topic-page__subtext'>
            <h4>The 14th Amendment of the Constitution guarantees birthright citizenship and states: </h4>
            <p className='text-quote'>
            "All persons born or naturalized in the United States and subject to the jurisdiction thereof, are citizens of the United States and of the State wherein they reside."
            </p>
            <h4>Protects infringement by the states as well as by the federal</h4>
            <h4>Expanded the due process clause of the 5th amendment</h4>
          </section>

          </div>
        </div>
        {/* 
        <div className="topic-page__actions">
          <Button inverse onClick={openMapHandler}>Application forms</Button>
        </div> 
        */}
      </Card>
    </div>
    </Fragment>
  )
}

export default BirthRight
