import React, { Fragment } from 'react';
import './Homepage.css';

import american from '../../images/american.jpg';
import law from '../../images/law.jpg';
import money from '../../images/money.jpg';
import passport from '../../images/passport.jpg';
import signature from '../../images/signature.jpg';
import multiflag from '../../images/multiflag.jpg';

import HomeTopic from '../components/HomeTopic';

const Homepage = () => {

  return (
    <Fragment>
      <header className="homepage__header">
        <div className="homepage__hero"></div>
          <h1>Here to help with your citizenship questions</h1>
          <aside className='arrow-down'>v</aside>
      </header>

      <div className="homepage__content">

        <div className="homepage__description">
          <p>Obtaining US citizenship can be very confusing process for many which results in unnecessary costs and work. We are here to help clear some of the confusion around the application process.</p>
        </div>

      <div className="row2x1">

  
        <HomeTopic
          title='Things to know'
          img={passport}
          link='/visas'
          description='Find out what you need to know first before applying.'
        />
        <HomeTopic
          title='Application Barriers'
          img={law}
          link='/barriers'
          description="What are the reasons most applicants don't apply"
        />
        <HomeTopic
          title='Application Limits'
          img={signature}
          link='/naturalization/limits'
          description='Know which forms you need to fill out to be prepared.'
        />
        <HomeTopic
          title='Cost and expenses'
          img={money}
          link='/naturalization'
          description="Process can get pretty expensive, don't pay more than what you need to."
        />
        <HomeTopic
          title='Interview process'
          img={american}
          link='/interview'
          description="Find out the typical questions you will be asked during your interview process."
        />
        <HomeTopic
          title='Know your rights'
          img={multiflag}
          link='/birth-right'
          description="You have rights at birth"
        />

  </div>
  </div>
    </Fragment>
  )
}

export default Homepage;
