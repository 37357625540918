import React, { Fragment, useState  } from 'react';
import './MainNavigation.css'

import { Link } from 'react-router-dom'
import MainHeader from './MainHeader';
import NavLinks from './NavLinks';
import SideDrawer from './SideDrawer';

import BackDrop from '../UIElements/Backdrop';

const MainNavigation = () => {

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const openDrawerHandler = () => setDrawerIsOpen(true);
  const closeDrawerHandler = () => setDrawerIsOpen(false);

  return (
    <Fragment>
      { drawerIsOpen && <BackDrop onClick={closeDrawerHandler}/> }
        <SideDrawer onClick={closeDrawerHandler} show={drawerIsOpen}>
          <nav className='main-navigation__drawer-nav'>
            <NavLinks/>
          </nav>
        </SideDrawer>

      
      <MainHeader>
        <button
          onClick={ openDrawerHandler }
          className='main-navigation__menu-btn'>
          <span/>
          <span/>
          <span/>
        </button>

        <h1 className='main-navigation__title'>
          <Link to='/'>U.S.Americano</Link>
        </h1>

        <nav className='main-navigation__header-nav'>
          <NavLinks/>
        </nav>
      </MainHeader>
    </Fragment>
  )
}

export default MainNavigation
