import React, { useState, Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import checklist from '../../images/checklist.jpg';

import './TopicPage.css';

const Limitations = () => {

  const [showMap, setShowMap] = useState(false);
  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  return (
    <Fragment>
    <Modal
      show={showMap} onCancel={closeMapHandler}
      header='Before Applying to become a US citizen'
      contentClass="topic-page__modal-content"
      footerClass='topic-item__modal-actions'
      footer={ <Button onClick={closeMapHandler}>Close</Button>  }    
      >

      <div className='text-container'>
      A person must be a permanent resident for 5 years, then they can apply to become a naturalized citizen
      </div>
    </Modal>


    <div className='topic-page'>
      <Card className='topic-page__card'>
        
        <div className="topic-page__image">
          <img src={checklist} alt='visa'/>
        </div>

        <div className="topic-page__info topic-large">
          <h1>Application Requirements</h1>
          <div className="topic-page__content">



          <section>
            
            <ul className="box-list">
              <li className="box-list__item">
                Must be the  age   of 18
              </li>
              <li className="box-list__item">
                Have not taken a trip of 6 or more months outside of the U.S  during the 3 or  5 yrs wait time
              </li>
              <li className="box-list__item">
                Have been a resident of state where you plan in applying for citizenship for about three months 
              </li>
              <li className="box-list__item">
                Have good character; Is suited to the average citizens in their own group
              </li>
              <li className="box-list__item">
                Need to pass the English language test and the civics test (Social studies)
              </li>
              <li className="box-list__item">
                You must served in the  U.S military or the Civic Service for the United State if called to  
              </li>
              <li className="box-list__item">
                Must required with the Selective service system; (federal student financial aid, state-funded student financial aid, federal employment, etc)
              </li>
              <li className="box-list__item">
                Must be able to defend the U.S Constitution 
              </li>


            </ul>
          </section>

          </div>
        </div>

        <div className="topic-page__actions">
          <Button inverse onClick={openMapHandler}>Before Applying</Button>
          <Button to='/barriers'>Barriers</Button>
        </div>
      </Card>
    </div>
    </Fragment>
  )
}

export default Limitations
