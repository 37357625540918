import React, { Fragment } from 'react';
import './Visa.css';


import VisaVisa from '../components/VisaVisa';
import VisaGreencard from '../components/VisaGreencard';


const PlaceItem = () => {



  return (
    <Fragment>
    <header className="visa-header">
      <h1>Visas and Greencards</h1>
    </header>

    <ul className='topic-item__list'>
      <VisaVisa />
      <VisaGreencard />
    </ul>
    </Fragment>
  )
}

export default PlaceItem
