import React, {  Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

import law from '../../images/law.jpg';


import './TopicPage.css';

const Barriers = () => {


  return (
    <Fragment>

    <div className='topic-page'>
      <Card className='topic-page__card'>
        
        <div className="topic-page__image">
          <img src={law} alt='naturalization limits'/>
        </div>

        <div className="topic-page__info  topic-large">
          <h1>3 Barriers to Naturalization and Citizenship</h1>
          <h3 className='topic-page__subtitle'>There are barriers for people who are citizens of another country trying to naturalize to the United States.</h3>
          <div className="topic-page__content">


          <section className='topic-page__subtext'>
          <h3 className='subtext-title underline'>Legal Barrier</h3>
              <h4 className='subtext-bullet'>
              Millions cannot obtain legal permanent residency and obtain a “green card” through…
              </h4>
              <ul className="subtext-num-list">
                <li>Family/Marriage</li>

                <li>Employment </li>
                <li>Refugee/Asylum</li>
                <li>Crime Victims</li>
              </ul>
              <p className="text-quote">According to the Pew Hispanic Center, there are almost 11 million undocumented people (6 million from Mexico) who are NOT eligible to apply through the current legal pathways in the US.</p>

              <h4 className='subtext-bullet'>
              You may qualify if you overstayed your visa or is often times reserved for those who face religious or political oppression whom are seeking political asylum
              </h4>
              <br/>
              <h3 className='subtext-title underline'>Financial Barrier</h3>
              <h4 className='subtext-bullet'>Sometimes people wanting to naturalize and become citizens are not able to pay the processing fees.</h4>

              <br/>
              <h3 className='subtext-title underline'>Language Barrier</h3>
              <h4 className='subtext-bullet'>Many people find it hard to pass the naturalization tests that are performed in english </h4>
              <ul className="subtext-num-list">
                
              <li>English language test (reading, writing and speaking).</li>
              <li>Civics Test - U.S History or U.S Government</li>
                
              </ul>
          </section>

          </div>
        </div>

      </Card>
    </div>
    </Fragment>
  )
}

export default Barriers
