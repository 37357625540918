import React from 'react';
import './MainLayout.css'

const MainLayout = ({
  children
}) => (
  <div className='main-layout__center'>
    { children }
  </div>
)

export default MainLayout
