import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from './history';
import './styles';

import MainLayout from './shared/components/Layout/MainLayout';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import Homepage from './citizen/pages/Homepage';


import VisaPage from './topics/pages/VisaPage';
import Requirements from './topics/pages/Requirements';


import Naturalization from './topics/pages/Naturalization';
import NaturalizationLimits from './topics/pages/NaturalizationLimits';
import Information from './topics/pages/Information';
import BirthRight from './topics/pages/BirthRight';
import Interview from './topics/pages/Interview';
import Amnesty from './topics/pages/Amnesty';
import Barriers from './topics/pages/Barriers';

const App = () => {
  return (
    <Router history={history}>
      <MainNavigation/>
      
      
        <Switch>
          
            <MainLayout>
            <Route path='/' exact component={Homepage} />

            <Route path='/information' exact component={Information} />
            <Route path='/interview' exact component={Interview} />
            <Route path='/visas' exact component={VisaPage} />
            <Route path='/birth-right' exact component={BirthRight} />


            <Route path='/naturalization' exact component={Naturalization} />
            <Route path='/naturalization/limits' exact component={NaturalizationLimits} />

            <Route path='/requirements' exact component={Requirements} />
            <Route path='/amnesty' exact component={Amnesty} />
            <Route path='/barriers' exact component={Barriers} />


            </MainLayout>



            <Redirect to='/'/>
        </Switch>
    </Router>
  )
};

export default App;