import React, { useState, Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import nattest from '../../images/nattest.jpg';

import './TopicPage.css';

const Naturalization = () => {

  const [showMap, setShowMap] = useState(false);
  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  return (
    <Fragment>
    <Modal
      show={showMap} onCancel={closeMapHandler}
      header='What is naturalization?'
      contentClass="modal__content"
      footerClass='topic-item__modal-actions'
      footer={ <Button onClick={closeMapHandler}>Close</Button>  }    
      >

      <div className='text-container'>
        Naturalization is the process by which U.S. citizenship is granted to a foreign citizen or national after he or she fulfills the requirements established by Congress in the Immigration and Nationality Act (INA)
      </div>
    </Modal>


    <div className='topic-page'>
      <Card className='topic-page__card'>
        
        <div className="topic-page__image">
          <img src={nattest} alt='naturalization test'/>
        </div>

        <div className="topic-page__info  topic-large">
          <h1>Naturalization Process</h1>
          <div className="topic-page__content">
            <div className="topic-page__subtext">
              <h4 className='italic'>Estimated Cost: $725</h4>
              <h4 className='italic'>Estimated Time Required: At least 14 months, as of 2019</h4>
            </div> 

          <section>
            <ul className="box-list">

              <li className="box-list__item">
              Determine eligibility for naturalization.
              </li>
              <li className="box-list__item">
              Prepare and submit Form N-400. (Application for Naturalization)
              </li>
              <li className="box-list__item">
              Attend a biometrics appointment, if applicable.
              </li>
              <li className="box-list__item">
              Complete an interview and await USCIS decision.
              </li>
              <li className="box-list__item">
              Take the Oath of Allegiance.
              </li>
              <li className="box-list__item">
              Understand your rights and responsibilities as a U.S citizen
              </li>

            </ul>
          </section>

          </div>
        </div>

        <div className="topic-page__actions">
          <Button inverse onClick={openMapHandler}>What is Naturalization?</Button>
          <Fragment>
              <Button to='/naturalization/limits'>Naturalization Limits</Button>
          </Fragment>
        </div>
      </Card>
    </div>
    </Fragment>
  )
}

export default Naturalization
