import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';

import { CSSTransition } from 'react-transition-group';
import Backdrop from './Backdrop';

const ModalOverlay = ({
  className,
  style,
  header,
  headerClass,
  contentClass,
  children,
  footerClass,
  footer,

  onSubmit
}) => {
  const content = (
    <div
      style={style}
      className={`modal ${className}`}>

      <header className={`modal__header ${headerClass}`}>
        <h2>{header}</h2>
      </header>

      <form onSubmit={onSubmit ? onSubmit: (e) => e.preventDefault()}>
        <div className={`modal__content ${contentClass}`}>
          {children}
        </div>

        <footer className={`modal__footer ${footerClass}`}>
          { footer }
        </footer>
      </form>
    </div>
  )
  return ReactDOM.createPortal(content , document.getElementById('modal-hook'))
};

// forward props to ModalOverlay
const Modal = ({
  show=false,
  className='',
  style={},

  header='Header Section',
  headerClass='',

  children,
  contentClass='',

  footer='Footer Section',
  footerClass='',

  onCancel=()=>{}
}) => {
  const props = {
    className,
    style,

    header,
    headerClass,

    children,
    contentClass,

    footer,
    footerClass
  };

  return (
    <Fragment>
      { show && <Backdrop onClick={onCancel}/>}
      <CSSTransition
        classNames='modal'
        in={show} mountOnEnter unmountOnExit timeout={200}>

        <ModalOverlay {...props}/>

      </CSSTransition>
    </Fragment>
  )
}

export default Modal
