import React, { useState, Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import law from '../../images/law.jpg';


import './TopicPage.css';

const NaturalizationProcess = () => {

  const [showMap, setShowMap] = useState(false);
  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  return (
    <Fragment>
    <Modal
      show={showMap} onCancel={closeMapHandler}
      header='For forms and info'
      contentClass="topic-page__modal-content"
      footerClass='topic-item__modal-actions'
      footer={ <Button onClick={closeMapHandler}>Close</Button>  }    
      >

      <div className='text-container'>
        <p>Please visit:
          <a rel='noreferrer' href="https://www.uscis.gov/n-400">
          US Citizenship and Immigration website.</a>
        </p>
      </div>
    </Modal>


    <div className='topic-page'>
      <Card className='topic-page__card'>
        
        <div className="topic-page__image">
          <img src={law} alt='naturalization limits'/>
        </div>

        <div className="topic-page__info  topic-large">
          <h1>Naturalization Limits</h1>
          <div className="topic-page__content">

          <section>
            <ul className="box-list">
              <li className="box-list__item">
                Must be the  age   of 18
              </li>
              <li className="box-list__item">
                Have not taken a trip of 6 or more months outside of the U.S  during the 3 or  5 yrs wait time
              </li>
              <li className="box-list__item">
              Must be at least 18 years of age.
              </li>
              <li className="box-list__item">
              Must have been a permanent and continuous resident of the United States for at least 5 years.
              </li>
              <li className="box-list__item">
              Show proof that you have resided for at least 3 months in the state or USCIS district you reside in.
              </li>
              <li className="box-list__item">
              Able to read, write, and speak basic English.
              </li>
              <li className="box-list__item">
              Have basic understanding of U.S. history and government.
              </li>
              <li className="box-list__item">
              Have basic understanding of U.S. history and government.
              </li>
              <li className="box-list__item">
              Demonstrate an attachment to the principles and ideals of the U.S. Constitution
              </li>
            </ul>
          </section>

          </div>
        </div>

        <div className="topic-page__actions">
          <Button inverse onClick={openMapHandler}>Application forms</Button>
              {/* <Button to='/naturalization/limits'>Naturalization Limits</Button> */}
        </div>
      </Card>
    </div>
    </Fragment>
  )
}

export default NaturalizationProcess
