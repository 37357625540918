import React, {  Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

// import Button from '../../shared/components/FormElements/Button';
// import Modal from '../../shared/components/UIElements/Modal';
import visa from '../../images/visa.jpg';


const Visa_V = () => {

  // const [showMap, setShowMap] = useState(false);
  // const openMapHandler = () => setShowMap(true);
  // const closeMapHandler = () => setShowMap(false);


  

  return (
    <Fragment>



    <li className='topic-item'>
      <Card className='topic-item__card'>
        <h2 className='topic-item__title'>Visa</h2>
        <div className="topic-item__image">
          <img src={visa} alt='visa'/>
        </div>

        <div className="topic-item__info">
          <h3>What is a visa?</h3>
          <div className="topic-item__content">
            <div className='topic-item__list-text'>
              <p>Only a temporary pass that lets you enter the United States for a specific period of time</p>
              <p>Immigrant visa: a person can become a permanent resident immediately once entered into the U.S. and then will receive a green card soon after.</p>
              <p>Nonimmigrant visa: this is only temporary. Must leave by the date shown on the I-94 that they are issued when entered.</p>
            </div>
          </div>
        </div>

      </Card>
    </li>
    </Fragment>
  )
}

export default Visa_V
