import React, { Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

import Button from '../../shared/components/FormElements/Button';
import nattest from '../../images/nattest.jpg';

import './TopicPage.css';

const Information = () => {



  return (
    <Fragment>
    <div className='topic-page center'>
      <Card className='topic-page__card max-w50'>
        
        <div className="topic-page__image">
          <img src={nattest} alt='naturalization test'/>
        </div>

        <div className="topic-page__info">
          <h1>Citizenship Information</h1>
          <div className="topic-page__content">
          <p>Helpful topics on some of the most common questions people will have.</p>
          </div>
        </div>



        <div className="topic-page__actions action-list">
          <Button to='/visas'>Visas and Greencards</Button>
          <Button to='/requirements'>Requirements</Button>
          <Button to='/barriers'>Barriers</Button>
          <Button to='/birth-right'>Birthright Citizenship</Button>
          <Button to='/naturalization'>Naturalization</Button>
          <Button to='/amnesty'>Amnesty</Button>
          <Button to='/interview'>Interview</Button>
        </div>
      </Card>
    </div>
    </Fragment>
  )
}

export default Information
