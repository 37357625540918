import React, { useState, Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import law from '../../images/law.jpg';


import './TopicPage.css';

const Amnesty = () => {

  const [showMap, setShowMap] = useState(false);
  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  return (
    <Fragment>
    <Modal
      show={showMap} onCancel={closeMapHandler}
      header='Immigration Amnesty'
      contentClass="topic-page__modal-content"
      footerClass='topic-item__modal-actions'
      footer={ <Button onClick={closeMapHandler}>Close</Button>  }    
      >

      <div className='text-container'>
        <p>Persons whos immigration status has expired may qualify for immigration amnesty.</p>
      </div>
    </Modal>


    <div className='topic-page'>
      <Card className='topic-page__card'>
        
        <div className="topic-page__image">
          <img src={law} alt='naturalization limits'/>
        </div>

        <div className="topic-page__info  topic-large">
          <h1>Amnesty Citizenship</h1>
          <div className="topic-page__content">

          

          <section className='topic-page__subtext'>
          <h3 className='subtext-title'>What is amnesty?</h3>

              <h4 className='subtext-bullet'>
              The process in which legal immigration status is granted to those who entered the United States illegally 
              </h4>
              <h4 className='subtext-bullet'>
              You may qualify if you overstayed your visa or is often times reserved for those who face religious or political oppression whom are seeking political asylum
              </h4>
              <br/>
              <h3 className='subtext-title'>Requirements to apply</h3>
              <h4 className='subtext-bullet'>No criminal record</h4>
              <h4 className='subtext-bullet'>Must have lived in the United States for 10+ years</h4>
              <h4 className='subtext-bullet'>Person must be facing persecution or harm if they return to their home country</h4>
          </section>

          </div>
        </div>

        <div className="topic-page__actions">
          <Button inverse onClick={openMapHandler}>Expired immigration status?</Button>
              {/* <Button to='/naturalization/limits'>Naturalization Limits</Button> */}
        </div>
      </Card>
    </div>
    </Fragment>
  )
}

export default Amnesty
