import React, { useState, Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';

import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import interview from '../../images/interview.jpg';


import './TopicPage.css';
import './Interview.css';

import interview_sample from './interview.json';



const Interview = () => {
  const [showQuestion, setShowQuestion] = useState({
    show: false,
    question: '',
    answer: ''
  });
  const openMapHandler = (id) => {
    setShowQuestion({
      show: true,
      question: interview_sample[id].question,
      answer: interview_sample[id].answer
    });
  };
  const closeMapHandler = () => {

    setShowQuestion(prev => ({
      ...prev,
      show: false
    }));
  }

  const renderQuestions = () => {
    return interview_sample.map((q,index) => {
      return (
        <div
          key={index}
          className="interview__question-group">
            <p className="interview__question">
              {q.question}
            </p>
            <Button inverse onClick={() => {
              openMapHandler(index)
            }}>Answer</Button>
        </div>
      )
    })
  }



  return (
    <Fragment>
    <Modal
      show={showQuestion.show} onCancel={closeMapHandler}
      header={showQuestion.question}
      contentClass="topic-page__modal-content"
      footerClass='topic-item__modal-actions'
      footer={ <Button onClick={closeMapHandler}>Close</Button>  }    
      >

      <div className='text-container'>
          <p>{showQuestion.answer}</p>
      </div>
    </Modal>


    <div className='topic-page'>
      <Card className='topic-page__card'>
        
        <div className="topic-page__image">
          <img src={interview} alt='interview process'/>
        </div>


        <div className="topic-page__info">
          <h1>The Interview</h1>
          <div className="topic-page__content">
            <p className='interview-description'>Around 10 questions are asked about the United States government and its history.</p>
            <h4 className="interview-subtitle">
              Here are some example questions
            </h4>
          </div>
        </div>

        <div className="topic-page__actions">
          { renderQuestions() }
          
        </div>
      </Card>
    </div>
    </Fragment>
  )
}

export default Interview
