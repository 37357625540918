import React, {  Fragment } from 'react';
import Card from '../../shared/components/UIElements/Card';


import greencard from '../../images/greencard.jpg';



const Visa_V = () => {



  return (
    <Fragment>



    <li className='topic-item'>
      <Card className='topic-item__card'>
        <h2 className='topic-item__title'>Greencard</h2>
        <div className="topic-item__image">
          <img src={greencard} alt='greencard'/>
        </div>

        <div className="topic-item__info">
          <h3>What is a greencard?</h3>
          <div className="topic-item__content">
            <div className='topic-item__list-text'>
              <p>A permits that allows someone to be in the United States but it also allows allows them to stay as long as they want.</p>
              <p>Received once becoming a U.S. lawful permanent resident.</p>
              <p>Usually holds a immigrant visa first or applied for a permanent residence from within the United States.</p>
              <p>A green card holder is usually used for the lawful permanent residents and as lawful conditional residents, although often expires in two years</p>
            </div>
          </div>
        </div>


      </Card>
    </li>
    </Fragment>
  )
}

export default Visa_V
