import React from 'react';
import { Link } from 'react-router-dom';
import './HomeTopic.css';

const HomeTopic = ({ title, img, description, link }) => {
  return (
    <section className="homepage__topic">
      <Link to={link}>
        <h1>{title}</h1>
        <figure className="figure-image">
          <img src={img} alt="" />
        </figure>
        <figcaption>
          <p>{description}</p>
        </figcaption>
      </Link>
    </section>
  );
};

export default HomeTopic;
